import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../components/AxiosConfig';
import { useSearchParams } from 'react-router-dom';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ authenticated, children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [retry, setRetry] = useState(false);
  const [timeout, setTimeoutState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const publicRoutes = ['/termsofservice'];

  useEffect(() => {
    if (publicRoutes.includes(location.pathname)) {
      setLoading(false);
      return;
    }
    
    const fetchUserData = async () => {
      if (userData) {
        return;
      }
      if(location.pathname === '/'){
        return;
      }
      setLoading(true);
      setTimeoutState(false);
      try {
        if (authenticated) {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`);
          if (response.status === 200) {
            if (!response.data.isInitalLanguagesConfigured) {
              const libraryLanguage = searchParams.get('libraryLanguage') || 'English';
              const translationLanguage = searchParams.get('translationLanguage') || 'English';
              response.data.libraryLanguage = libraryLanguage;
              response.data.translationLanguage = translationLanguage;
              response.data.explanationLanguage = translationLanguage;
                await axios.post(`${process.env.REACT_APP_API_URL}/api/user/initalLaguages`, { libraryLanguage: libraryLanguage, translationLanguage: translationLanguage })
                .then(response => {
                })
                .catch(error => {
                  console.error('There was an error setting the language!', error);
                });
            }
            setUserData(response.data);
            setRetry(false); // No need to retry if fetch succeeded
          } else {
            setRetry(true); // Set retry to true if fetch failed
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setRetry(true); // Retry on error
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();

    // Retry only if retry is true and userData is still not set
    if (retry && !userData) {
      fetchUserData();
    }
  }, [location.pathname, retry]);

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setTimeoutState(true); // Trigger timeout after 20 seconds
      }, 30000);

      return () => clearTimeout(timer); // Cleanup timeout on unmount or when loading stops
    }
  }, [loading]);

  const handleRefresh = () => {
    setRetry(true); // Trigger a retry
    setTimeoutState(false); // Reset timeout state
  };

  return (
    <UserContext.Provider value={{ userData, setUserData, loading }}>
      {loading && timeout && location.pathname !== '/'? (
        <div style={styles.container}>
          <p style={styles.message}>Loading is taking longer than expected. Please refresh.</p>
          <button style={styles.button} onClick={handleRefresh}>Refresh</button>
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};

// Inline styles for centering and improving appearance
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f9f9f9',
    color: '#333',
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: '1rem',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default UserProvider;
