import React from "react";
import PropTypes from "prop-types";
import Input from "@mui/material/Input";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import styles from './SearchBar.module.css';
import classNames from "classnames";

const StyledIconButton = ({ children, onClick, isHidden, disabled }) => {
  const buttonClass = classNames(styles.iconButton, {
    [styles.iconButtonHidden]: isHidden
  });

  return (
    <div style={{marginBottom:'10px', marginTop: '15px',cursor:'pointer', marginRight: '10px', display: `${isHidden ? 'none' : 'block'}`}}
      onClick={onClick}
      disabled={disabled}
      >
      {children}
    </div>
  );
};

const SearchBar = React.forwardRef(
  ({
    cancelOnEscape,
    className,
    closeIcon = <SearchIcon s={{heigh: '15px'}}/>,
    disabled,
    onCancelSearch,
    onRequestSearch,
    searchIcon = <SearchIcon s={{marginBottom: '15px'}}/>,
    style,
    ...inputProps
  }, ref) => {
    const inputRef = React.useRef();
    const [value, setValue] = React.useState(inputProps.value || "");

    React.useEffect(() => {
      setValue(inputProps.value);
    }, [inputProps.value]);

    const handleInput = (e) => {
      setValue(e.target.value);
      if (inputProps.onChange) {
        inputProps.onChange(e.target.value);
      }
    };

    const handleRequestSearch = () => {
      if (onRequestSearch) {
        onRequestSearch(value);
      }
    };

    const handleCancel = () => {
      setValue("");
      if (onCancelSearch) {
        onCancelSearch();
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === "Enter") {
        handleRequestSearch();
      } else if (cancelOnEscape && e.key === "Escape") {
        handleCancel();
      }
      if (inputProps.onKeyUp) {
        inputProps.onKeyUp(e);
      }
    };

    return (
      <Paper className={classNames(styles.root, className)} style={style}>
        <div className={styles.searchContainer}>
          <Input
            {...inputProps}
            ref={inputRef}
            onBlur={handleInput}
            value={value}
            onChange={handleInput}
            onKeyUp={handleKeyUp}
            className={styles.input}
            disableUnderline
            disabled={disabled}
            fullWidth
          />
        </div>
        <StyledIconButton 
        onClick={handleRequestSearch}
        isHidden={!value}
        disabled={disabled}
      >
        {/* Assuming searchIcon is a valid React element */}
        {searchIcon}
      </StyledIconButton>
      <StyledIconButton
        onClick={handleCancel}
        isHidden={value}
        disabled={disabled}
      >
        {/* Assuming closeIcon is a valid React element */}
        {closeIcon}
      </StyledIconButton>
      </Paper>
    );
  }
);

SearchBar.propTypes = {
  cancelOnEscape: PropTypes.bool,
  className: PropTypes.string,
  closeIcon: PropTypes.node,
  disabled: PropTypes.bool,
  onCancelSearch: PropTypes.func,
  onRequestSearch: PropTypes.func,
  placeholder: PropTypes.string,
  searchIcon: PropTypes.node,
  style: PropTypes.object,
  value: PropTypes.string,
};

export default SearchBar;
