import React from 'react';
import './PremiumTable.css';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { checkPremium } from "../../assets";
import { useState, useEffect } from "react";
const PremiumTable = ({ plans, featuresName }) => {
    
  const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const checkScreenSize = () => {
          setIsMobile(window.innerWidth < 768); // Mobile if screen width is less than 768px (Tailwind's md breakpoint)
        };
    
        checkScreenSize(); // Check on component mount
        window.addEventListener("resize", checkScreenSize); // Add listener for resize events
    
        return () => window.removeEventListener("resize", checkScreenSize); // Cleanup on unmount
      }, []);
    return (
        <div className="table-container">
            <table className="subscription-table">
                <thead>
                    <tr>
                        <th>{featuresName}</th>
                        {plans.map(plan => (
                            <th key={plan.name}>{plan.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {plans[0].featuresTable.map((feature, index) => (
                        <tr key={index}>
                            <td data-label="Feature" style={{fontSize: '1.3rem'}}>{feature.name}</td>
                            {plans.map(plan => (
                                <td key={plan.name} data-label={plan.name} style={{fontSize: '1.3rem'}}>
                                    {typeof plan.featuresTable[index].included === 'boolean' ? (
                                        plan.featuresTable[index].included ? 
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <CheckIcon style={{ color: 'green', height: '25px', width: '25px', marginRight: '5px' }} />
                                        </div>

                                        : 
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <CloseIcon size={27} style={{color: 'red', marginRight: '5px'}}/>
                                        </div>
                                        
                                    ) : (
                                        <span style={{fontWeight: plan.name === 'Premium'? 'bold' : 'normal', fontSize: '1.3rem'}}>{plan.featuresTable[index].included}</span>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {isMobile && (
  <div className="relative z-1 flex items-center justify-center h-[39rem] mb-5 p-8 border border-n-1/10 rounded-3xl overflow-hidden lg:p-20 xl:h-[46rem]">
    <div className="absolute top-0 left-0 w-full h-full pointer-events-none md:w-3/5 xl:w-auto">
    </div>

    <div
      className="relative z-1 "
      style={{ color: 'black' }}
    >
      <ul className="body-2 text-left">
        {plans[1].featuresTable.map((feature, index) => (
          <li
            key={index}
            className="flex items-start py-4 border-t"
          >
            <img width={24} height={24} src={checkPremium} />
            <p className="ml-4" style={{ fontSize: '1.15rem' }}>
              {feature.name} {feature.included !== true &&<strong style={{fontWeight: '600'}}>[{feature.included}]</strong>}
            </p>
          </li>
        ))}
      </ul>
    </div>
  </div>
)}

        </div>
    );
};

export default PremiumTable;
