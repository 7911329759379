import React from "react";
import { checkPremium } from "../../assets";
import { uncheck } from "../../assets";

const Box = (props) => {
  const { title, btnClass, btnTitle, price, feature, perMonth, disabled, onClick } = props;

  return (
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-sm">
        <div className="card-header py-3" style={{background:title === 'Premium'? '#00B2FF': '#96e4fd'}}>
          <h1  style={{color: 'white', fontSize: '1.5rem', fontWeight: 'bold'}}>{title}</h1>
        </div>
        <div className="card-body" style={{background: title === 'Premium' ? '#e5f9ff' : 'white', border: title === 'Premium' ? '1px solid black' : '', borderRadius: '3px'}} >
          <h1 className="card-title pricing-card-title" style={{fontSize: '3.5rem'}}>
            {price} <small className="text-muted fw-light">{perMonth}</small>
          </h1>
          <ul className="list-unstyled mt-3 mb-4">
          {feature &&
feature.map((data, index) => {
  const sentences = data.text.split('\n');
  return (
    <li
      key={index}
      className="feature-item"
      style={{ padding: 5, textAlign: 'left', fontSize: '1.2rem' }}
    >
      <div className="d-flex align-items-start">
        {data.available ? (
          <img
            width={24}
            height={24}
            src={checkPremium}
            style={{
              marginRight: '10px',
              alignSelf: 'center',
            }}
          />
        ) : (
          <img
            width={24}
            height={24}
            src={uncheck}
            style={{
              marginRight: '10px',
              alignSelf: 'center',
            }}
          />
        )}
        <div>
          {sentences.map((sentence, i) => (
            <div key={i} style={{ maxWidth: '17rem' }}>
              <div
                className={i === 0 ? '' : 'feature-text'}
                dangerouslySetInnerHTML={{ __html: sentence }}
              ></div>
              <hr style={{ marginTop: '5px', margin: '5px 0', border: 'none', borderTop: '2px solid black', marginBottom: '10px' }} />
            </div>
          ))}
        </div>
      </div>
    </li>
  );
})}


          </ul>
          <button
            type="button"
            className={`w-100 btn btn-lg ${btnClass}`}
            disabled={disabled}
            style={{background: title === 'Premium' ?'#00B2FF' : 'grey', borderColor: title === 'Premium' ? '#00B2FF' : 'grey', color: 'white', fontWeight: 'bold'}}
            onClick={onClick}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Box;
