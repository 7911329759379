import React from "react";
import "./PremiumBox.css";

const PremiumBox = (props) => {
  const { title, btnClass, btnTitle, price, yearlyCost, originalYearlyCost, saveAmount, billedYearly, saveName, perMonth, yearlyCostName, onClick, disabled } = props;
  return (
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-sm">
        <div className={`card-header py-3 ${billedYearly ? "highlight" : ""}`} style={{background:billedYearly ? '#00B2FF': '#96e4fd'}}>
          {billedYearly && <div className="save-banner">{saveName} {saveAmount}</div>}
          <h4  style={{color: 'white', fontWeight: 'bold', fontSize: '1.4rem'}} >{title}</h4>
        </div>
        <div className="card-body">
          <h1 className="card-title pricing-card-title" style={{fontSize: '2.5rem'}}>
            {price} <small className="text-muted fw-light">{perMonth}</small>
          </h1>
          <div className="yearly-cost">
            {yearlyCostName} <span className="original-cost">{billedYearly ? originalYearlyCost : ""}</span> <span className="cost" style={{fontSize: '2rem'}}>{yearlyCost}</span>
          </div>
          <button
            type="button"
            className={`w-100 btn btn-lg ${btnClass}`}
            style={{background: billedYearly ? '#00B2FF': '#96E4FD', borderColor: billedYearly ? '#00B2FF': '#96E4FD', color: 'white'}}
            onClick={onClick}
            disabled={disabled}
          >
            {btnTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PremiumBox;
