import React, { useEffect, useState, useRef } from "react";
import './WordContext.css';
import { PiSpeakerHigh } from 'react-icons/pi';
import { FaRegCircleQuestion } from 'react-icons/fa6';
import BarLoader from 'react-spinners/BarLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import CloseIcon from '@mui/icons-material/Close';
import UnlockIcon from '../../icons/UnlockIcon';
import ArrowRightTopIcon from '../../icons/ArrowRightTopIcon';
import { useTranslation } from 'react-i18next';
import { voiceLanguage } from '../../constants/global.js';

const SPEAKER_ICON_COLOR = '#00B2FF';

const WordContext = ({ position, displayWordContext, text, onExplainContextClick, translation, wordContextSentence, wordContextCurrentSentence, wordContextTranslationId, currentTheme, onShowPremium, wordContextSentenceLimit, bookData }) => {

  const wordContextRef = useRef(null);
  const { t } = useTranslation()
  const [topPosition, setTopPosition] = useState('115px');
  const [voiceEnabled, setVoiceEnabled] = useState();
  const [availableVoices, setAvailableVoices] = useState(window.speechSynthesis.getVoices());

  window.speechSynthesis.onvoiceschanged = () => {
    setAvailableVoices(window.speechSynthesis.getVoices());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wordContextRef.current && !wordContextRef.current.contains(event.target)) {
        onExplainContextClick(false); // Close the component
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onExplainContextClick]);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Adjust the pixel width for your mobile breakpoint
        setTopPosition('100px');
      } else {
        setTopPosition('115px');
      }
    };
    handleResize(); // Set initial value based on current width

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(bookData){
      const isVoiceEnabled = voiceLanguage.find(x => x.name === bookData.language);
      setVoiceEnabled(isVoiceEnabled);
    }
  }, [bookData]);

  useEffect(() => {
    if (wordContextRef.current && displayWordContext === "block") {
      const rect = wordContextRef.current.getBoundingClientRect();

      if(rect.right > window.outerWidth){
        const outScreenWidth = rect.right - window.outerWidth;
        wordContextRef.current.style.left = `${wordContextRef.current.offsetLeft - outScreenWidth - 20 }px`;
      }

      if(rect.left < 0){
        wordContextRef.current.style.left = `${wordContextRef.current.offsetLeft  + Math.abs(rect.left)}px`;
      }
      
      if(rect.bottom > window.outerHeight){
        const windowHeight = rect.bottom - rect.top;
        wordContextRef.current.style.top = `${wordContextRef.current.offsetTop - (windowHeight + position.height + 15)}px`
      }else if(rect.bottom > window.innerHeight){
        const windowHeight = rect.bottom - rect.top;
        wordContextRef.current.style.top = `${wordContextRef.current.offsetTop - (windowHeight + position.height + 15)}px`
      }
    }
}, [position, wordContextSentence]);

const handleSpeakClick = () => {
  if (!voiceEnabled || !text) return;

  const utterance = new SpeechSynthesisUtterance(text.toLowerCase());
  if (voiceEnabled.lang) {
    const selectedVoice = availableVoices.find(voice => voice.name === voiceEnabled.lang);
    if (selectedVoice) {
      utterance.voice = selectedVoice;
    }
  }
  window.speechSynthesis.speak(utterance);
};

  const wordContextStyle = {
    top: position.y,
    left: position.x,
    display: displayWordContext,
    textAlign: 'center',
    marginRight: '10px',
    marginLeft: '10px',
    background: currentTheme === 'white' ? 'white' : (currentTheme === 'light' ? '#F0EEDF' : '#565656'),
    color: currentTheme === 'dark' ? 'white' : 'black'
  };

  const questionIconStyle = {
    marginRight: '2px',
    position: 'absolute',
    right: '100%',
    top: '14%',
  };

  const speakerIconStyle = {
    marginRight: '5px',
    marginTop: '2px',
    color: SPEAKER_ICON_COLOR,
    cursor: 'pointer',
    visibility: voiceEnabled ? 'visible': 'hidden',
    height: '18px',
    width: '18px'
  };

  const barLoaderStyle = {
    position: 'absolute',
    top: '34%',
    left: '40%',
  };

  const circleLoaderStyle = {
    position: 'absolute',
    top: '0%',
    // left: '-40%',
  };

  const showPremium = () => {
    onShowPremium(true);
  }

  return (
    <div ref={wordContextRef} className="wordcontext" style={wordContextStyle}>
     <div className="explainContext" onClick={() => onExplainContextClick(true)}>
      <FaRegCircleQuestion style={questionIconStyle} />
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          {t('explainInContext')}
          <ArrowRightTopIcon style={{ marginLeft: '4px', verticalAlign: 'middle' }} />
        </span>
      </div>
      
      <span className="closeWordContext" onClick={() => onExplainContextClick(false)}><CloseIcon style={{ width: '20px', height: '20px' }}/></span>
      <div>
  <div style={{ display: 'inline-flex', cursor: 'pointer'}} onClick={handleSpeakClick}>
    <PiSpeakerHigh style={speakerIconStyle} />
    <div style={{ fontWeight: 'bold' }}>{text}</div>
  </div>
  
  {/* Container for translation or loading bar */}
  <div className="explanationWordContext" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {translation ? (
      <div style={{ textAlign: 'center', marginTop: '-30px' }}>{translation}</div>
    ) : (
      <div style={{ position: 'absolute', top: '50px', left: '40%'}}>
        <BarLoader color={SPEAKER_ICON_COLOR} width='50px' cssOverride={barLoaderStyle} />
      </div>
    )}
  </div>
    <div className="hrInSentence">
    </div>
  <span className="inSentence">{t('inCurrentSentence')}:</span>

              {wordContextSentence ? (
    <div className="translation">
      <span>{wordContextSentence}</span>
    </div>
    ) : wordContextSentenceLimit === false ? (
    <div className="wordSentencesBar" style={{ top: topPosition }}>
      <ClipLoader color={SPEAKER_ICON_COLOR} size={12} cssOverride={circleLoaderStyle}/>
    </div>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center', marginTop:'5px' }}>
  <span
    style={{
      color: '#00B2FF',
      textDecoration: 'underline',
      display: 'flex',
      alignItems: 'center',
      gap: '5px', // add gap for spacing
    }}
  >
    <UnlockIcon style={{ height: '20px', width: '20px' }} />
    <span onClick={showPremium} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
      {t('unlockWithPremium')}
      <ArrowRightTopIcon />
    </span>
  </span>
</div>

  )}
</div>
    </div>
  );
};

export default WordContext;
