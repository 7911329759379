import React, { useState, useEffect }from 'react';
import "../wordsCountLimit/WordsCountLimit.css"
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import UnlockIcon from '../../icons/UnlockIcon';
import ArrowRightTopIcon from '../../icons/ArrowRightTopIcon';
import { useTranslation } from 'react-i18next';

const WordsCountLimit = ({ showWordLimit , onCloseWordsLimit, theme, onShowPremium, userData}) => {
  
  const [isVisible, setIsVisible] = useState('hidden');
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
    useEffect(() => {
        if(showWordLimit)
        {
          showWordLimit = false;
          setIsVisible('visible');
          const onPopupVisibleTimer = setTimeout(() => {
              setIsVisible('hidden');
              onCloseWordsLimit();
              setProgress(0);
          }, 5000);

          const onProgressTimer = setInterval(() => {
            setProgress((oldProgress) => {
              if (oldProgress === 100) {
                return 100;
              }
              const diff = 4;
              return Math.min(oldProgress + diff, 100);
            });
          }, 180);
    
          return () => {
            clearInterval(onPopupVisibleTimer);
            clearInterval(onProgressTimer);
          };
        }
    }, [showWordLimit]);

    const onClose = () => {
      setIsVisible('hidden');
    }

    const showPremium = () => {
      onShowPremium(true);
    }
  return (
    <div className='wordsLimitContent' style={{ 
      visibility: isVisible, 
      position: 'fixed', 
      bottom: '1rem', 
      left: '50%', 
      transform: 'translateX(-50%)', 
      width: '100%', 
      maxWidth: '400px', 
      margin: '0 auto', 
      boxShadow: '0px -2px 10px rgba(0,0,0,0.1)', 
      backgroundColor: 'white', 
      zIndex: 1000,
      background: theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5' : '#565656') }}>
        <span className="closeLimitPopup" onClick={() => onClose(false)}><CloseIcon style={{ width: '20px', height: '20px' }}/></span>
    <Box sx={{ width: '113%', color: 'orange', marginTop: '-10px' }}>
        <LinearProgress sx={{
            backgroundColor: theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5' : '#565656'),
            '& .MuiLinearProgress-bar': {
                backgroundColor: '#f4b738'
            }
        }}
        variant="determinate" 
        value={progress} />
    </Box>
    <div className='wordsLimitContent__header' style={{color: theme === 'dark' ? 'white' : 'black'}}>{t('highlightingWords')}</div>
    <div className='wordsLimitContent__description' style={{color: theme === 'dark' ? 'white' : 'black'}}>
      {userData && userData.isSubscriptionActive === false ? t('limitUserNonPremium') : t('limitUserPremium')}
      </div>
      {userData && userData.isSubscriptionActive === false && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: '#00B2FF', textDecoration: 'underline', display: 'inline-flex', alignItems: 'center' }}>
            <UnlockIcon style={{ height: '20px', width: '20px', marginRight: '5px' }} />
            <span onClick={showPremium} style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}>
              {t('limit20Words')}
              <ArrowRightTopIcon />
            </span>
          </span>
        </div>
)}


</div>

    );
};

export default WordsCountLimit;
