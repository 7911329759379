import React, { useState, useContext } from 'react';
import axios from '../../components/AxiosConfig';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Button } from '@mui/material';
import { bookLanguages, gptLanguages } from '../../constants/global.js';
import './LanguageSelector.css';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';
import textHighlightingDesktop from "../../assets/text_highlighting_desktop.gif";
import textHighlightingMobile from "../../assets/text_highlighting_mobile.gif";
import wordContextDesktop from "../../assets/word_context.gif";
import wordContextMobile from "../../assets/word_context_mobile.gif";
import explainInContextDesktop from "../../assets/explain_in_context_desktop.gif";
import explainInContextMobile from "../../assets/explain_in_context_mobile.gif";
import { KeycloakContext } from '../../components/KeycloakProvider';

const LanguageSelector = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLanguages, setFilteredLanguages] = useState(bookLanguages);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [translationLanguage, setTranslationLanguage] = useState(null);
  const [initialSelectedLanguage, setInitialSelectedLanguage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { keycloak } = useContext(KeycloakContext);

  const desktopGifs = [
    textHighlightingDesktop,
    wordContextDesktop,
    explainInContextDesktop,
  ];

  const mobileGifs = [
    textHighlightingMobile,
    wordContextMobile,
    explainInContextMobile,
  ];

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = (selectedLanguage ? gptLanguages : bookLanguages)
      .filter((language) =>
        (language.name.toLowerCase().includes(term) || language.native.toLowerCase().includes(term)) &&
        language.name !== initialSelectedLanguage?.name
      );
    setFilteredLanguages(filtered);
  };

  const handleLanguageClick = async (language) => {
    if (step === 0) {
      setInitialSelectedLanguage(language);
      setSelectedLanguage(language);
      setSearchTerm('');
      setFilteredLanguages(gptLanguages.filter(lang => lang.name !== language.name));
      setStep(1);
    } else if (step === 1) {
      setTranslationLanguage(language);
      setStep(2);
      // setLoading(true);
      // await axios.post(`${process.env.REACT_APP_API_URL}/api/user/initalLaguages`, { libraryLanguage: initialSelectedLanguage.name, translationLanguage: language.name })
      //   .then(response => {
      //     if (response.status === 200) {
      //       setStep(2);
      //       setLoading(false);
      //     }
      //   })
      //   .catch(error => {
      //     console.error('There was an error setting the language!', error);
      //     setLoading(false);
      //   });

    }
  };

  const handleNextStep = async () => {
    if (step < 4) {
      setStep((prevStep) => prevStep + 1);
    } else {
      await keycloak.login({ redirectUri: window.location.origin + `/books?libraryLanguage=${initialSelectedLanguage.name}&translationLanguage=${translationLanguage.name}` });
      //navigate('/books');
    }
  };

  const getGifForStep = (step) => {
    const isMobile = window.innerWidth <= 768;
    return isMobile ? mobileGifs[step - 2] : desktopGifs[step - 2];
  };

  const getStepTitle = (step) => {
    switch (step) {
      case 2:
        return t('howToTranslateASentence');
      case 3:
        return t('howToTranslateAWord');
      case 4:
        return t('howExplainContext');
      default:
        return `${t('step')} ${step - 1}`;
    }
  };

  if (loading) {
    return (
      <div className="spinner-container-language">
        <CircularProgress sx={{ color: '#00B2FF' }} />
      </div>
    );
  }

  return (
    <div className="container-language">
      {step < 2 ? (
        <>
          <h1 style={{fontSize: '2em', fontWeight: 'bold', margin: '0.5em 0'}}>{selectedLanguage ? t('whatsYourFirstLanguage') : t('whatLanguageLearning')}</h1>
          <input
            type="text"
            placeholder={t('searchForLanguage')}
            className="search-bar"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{background: 'white'}}
          />
          <div className="languages-grid">
            {filteredLanguages.map((language, index) => (
              <div key={index} className="language-card" onClick={() => handleLanguageClick(language)}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Flag
                    country={language.flag}
                    style={{ width: '60px', height: '60px', borderRadius: '5px', margin: 'auto' }}
                    aria-label={language.native}
                  />
                </div>

                <div className="language-name">{language.name}</div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <h1 style={{fontSize: '1.7em', fontWeight: 'bold', margin: '0.5em 0'}}>{getStepTitle(step)}</h1>
          <div className="image-container">
            <img src={getGifForStep(step)} alt={`Step ${step - 1} GIF`} className="step-gif" />
          </div>
          <Button
            variant="contained"
            sx={{
              margin: '10px auto',
              display: 'block',
              width: { xs: '98%', md: '30%' },
              color: 'white',
              backgroundColor: '#00b3ff',
              fontSize: { xs: '1.0em', md: '0.85em' }
            }}
            onClick={handleNextStep}
          >
            <div style={{ display: 'inline-flex', marginTop: '5px' }}>
              <span>{step === 4 ? t('startReading').toUpperCase() : t('next').toUpperCase()}</span>
            </div>
          </Button>
        </>
      )}
    </div>
  );
};

export default LanguageSelector;