import React, { useEffect, useState, useRef } from 'react';
import './BookSettings.css';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import axios from '../../components/AxiosConfig';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from "@mui/material/styles";
import { bookLanguages, gptLanguages } from '../../constants/global.js';
import BookSettingsLanguageAutocomplete from '../bookSettingsLanguageAutocomplete/BookSettingsLanguageAutocomplete';
import { useTranslation } from 'react-i18next';
import { useUser } from '../userContext/UserContext.js';
import { throttle } from 'lodash';

const CustomToggleButton = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#00B2FF",
    backgroundColor: '#00ff00',
  },
});

const BookSettings = ({ onFontSizeChange, bookData, onThemeChange, showBookSettings, onClose, textBookGenerated, isContentBookLoading }) => {
  const [selectedWordLanguage, setSelectedWordLanguage] = useState(null);
  const [selectedExplanationLanguage, setSelectedExplanationLanguage] = useState(null);
  const [theme, setTheme] = useState('');
  const [translationLanguage, setTranslationLanguage] = useState(null);
  const { t } = useTranslation();
  const totalDots = 5;
  const [filledDots, setFilledDots] = useState(null);
  const settingsRef = useRef(null); // Create a reference for the settings popup
  const bookSettingsLanguage = useRef(null); // Create a reference for the settings popup
  const bookSettingsTranslation = useRef(null); // Create a reference for the settings popup
  const { userData, loading, setUserData } = useUser();

  const staticDictionary = {
    1: 18,
    2: 20,
    3: 22,
    4: 24,
    5: 26,
  };

  useEffect(() => {
    if (settingsRef.current) {
      const rect = settingsRef.current.getBoundingClientRect();

      if (rect.right > window.outerWidth) {
        const outScreenWidth = rect.right - window.outerWidth;
        settingsRef.current.style.left = `${settingsRef.current.offsetLeft - outScreenWidth - 15}px`;
      }
    }
  }, [bookData]);

  useEffect(() => {
    if (bookData) {
      const fetchUserData = async () => {
        try {
          setSelectedWordLanguage(gptLanguages.find(x => x.name === userData.translationLanguage));
          setSelectedExplanationLanguage(gptLanguages.find(x => x.name === userData.explanationLanguage));
          setFilledDots(parseInt(Object.keys(staticDictionary).find(key => staticDictionary[key] === userData.fontSize)));
          onThemeChange(userData.theme);
          setTheme(userData.theme);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData();
    }
  }, [bookData]);

  // Close settings when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target) &&
        bookSettingsLanguage.current && !bookSettingsLanguage.current.contains(event.target) &&
        bookSettingsTranslation.current && !bookSettingsTranslation.current.contains(event.target)
      ) {
        onClose(''); // Call onClose when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const increaseFontSize = () => {
    if (filledDots < totalDots) {
      setFilledDots(filledDots + 1);
      onFontSizeChange(staticDictionary[filledDots + 1]);
      axios.put(`${process.env.REACT_APP_API_URL}/api/Book/${bookData.id}/fontsize/${staticDictionary[filledDots + 1]}`)
        .then(response => {})
        .catch(error => {});
    }
  };

  const handleChange = throttle((event, changedTheme) => {
    if (changedTheme === null || changedTheme === theme || textBookGenerated === false || isContentBookLoading) {
      return;
    }
    axios.put(`${process.env.REACT_APP_API_URL}/api/Book/${bookData.id}/theme/${changedTheme}`)
      .then(response => {
        setTheme(changedTheme);
        onThemeChange(changedTheme);
      })
      .catch(error => {});
    }, 2000);

  const decreaseFontSize = () => {
    if (filledDots > 1) {
      setFilledDots(filledDots - 1);
      onFontSizeChange(staticDictionary[filledDots - 1]);
      axios.put(`${process.env.REACT_APP_API_URL}/api/Book/${bookData.id}/fontsize/${staticDictionary[filledDots - 1]}`)
        .then(response => {})
        .catch(error => {});
    }
  };

  const onLanguageTranslationChange = (event, code) => {
    if (code === null) {
      return;
    }

    setSelectedWordLanguage(code);

    let changedUserData = userData;
    changedUserData.translationLanguage = code.name;
    setUserData(changedUserData);

    axios.put(
      `${process.env.REACT_APP_API_URL}/api/user/translationLanguage`,
      { translationLanguage: code.name },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then(response => {
        console.log('Translation language updated successfully:', response.data);
      })
      .catch(error => {
        console.error('Error updating translation language:', error);
      });
  };

  const onExplanationLanguageTranslationChange = async (event, code) => {
    setSelectedExplanationLanguage(code);
    if (code === null) {
      return;
    }

    let changedUserData = userData;
    changedUserData.explanationLanguage = code.name;
    setUserData(changedUserData);

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/user/explanationLanguage`, { explanationLanguage: code.name }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error saving library language:', error);
    }
  };

  const buttonStyle = {
    backgroundColor: 'transparent',
    color: '#00B2FF',
    padding: '10px 20px',
    border: '1px solid #00B2FF',
    borderRadius: '5px',
    cursor: 'pointer',
    outline: 'none',
    fontSize: "20px",
    marginRight: "5px",
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    color: '#00B2FF',
  };

  return (
    <div ref={settingsRef} className="wordcontextx">
      <div className="popup">
        <div className="popup-inner" style={{
          display: showBookSettings ? 'block' : 'none',
          background: theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5' : '#565656'),
          color: theme === 'dark' ? 'white' : 'black',
          position: 'relative',
        }}>
          <CloseIcon style={closeButtonStyle} onClick={() => onClose('')} />
          <div className="color-mode">
            <div className="color-options">
              <button style={buttonStyle} onClick={decreaseFontSize}>
                a
                <ExpandMoreIcon fontSize="small" />
              </button>
              <button style={buttonStyle} onClick={increaseFontSize}>
                A <ExpandLessIcon fontSize="small" />
              </button>
            </div>
            <div style={{ display: 'flex', gap: '10px', marginBottom: '10px', marginTop: '10px', justifyContent: 'center' }}>
              {Array.from({ length: totalDots }, (_, index) => (
                <div
                  key={index}
                  style={{
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                    backgroundColor: index < filledDots ? '#00B2FF' : (theme === 'light' ? 'grey' : 'lightgrey'),
                  }}
                />
              ))}
            </div>
          </div>
          <div className="night-mode" style={{ marginBottom: '0.5rem' }}>
            <label style={{ float: 'left', marginBottom: '0.3rem' }}>{t('wordsTranslationLanguage')}</label>
            <BookSettingsLanguageAutocomplete
              languages={gptLanguages}
              selectedLanguage={selectedWordLanguage}
              onLanguageChange={onLanguageTranslationChange}
              theme={theme}
              ref={bookSettingsLanguage}
            />
          </div>
          <div className="night-mode">
            <label style={{ float: 'left', marginBottom: '0.3rem' }}>{t('explanationContextLanguage')}</label>
            <BookSettingsLanguageAutocomplete
              languages={gptLanguages}
              selectedLanguage={selectedExplanationLanguage}
              onLanguageChange={onExplanationLanguageTranslationChange}
              theme={theme}
              ref={bookSettingsTranslation}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ float: 'left', marginTop: '0.5rem', marginBottom: '0.3rem' }}>{t('themes')}</label>
            <ToggleButtonGroup
              color="primary"
              value={theme}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              style={{ width: '100%' }}
            >
              <CustomToggleButton value="white" style={{ flex: 1, background: 'white', fontWeight: 'bold' }}>{t('white')}</CustomToggleButton>
              <CustomToggleButton value="light" style={{ flex: 1, background: '#F0EEDF', fontWeight: 'bold' }}>{t('light')}</CustomToggleButton>
              <CustomToggleButton value="dark" style={{ flex: 1, background: '#AAAAAA', fontWeight: 'bold' }}>{t('dark')}</CustomToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookSettings;
