import React, { useState, useEffect } from 'react';
import './Page.css';
import { FaArrowLeft } from 'react-icons/fa';
import axios from '../../components/AxiosConfig';
import Spinner from 'react-bootstrap/Spinner';
import './ManageSubscription.css';
import { useTranslation } from 'react-i18next';

const ManageSubscription = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [additionalFeedback, setAdditionalFeedback] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoadingData(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/subscription/current`)
      .then((response) => {
        setSubscription(response.data);
        setIsLoadingData(false);
      })
      .catch((error) => {
        setIsLoadingData(false);
        console.error('There was an error fetching the data!', error);
        setError(t('errorFetch'));
      });
  }, []);

  const handleBackClick = () => {
    window.location.href = '/books';
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleReasonChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedReasons((prev) => [...prev, value]);
    } else {
      setSelectedReasons((prev) => prev.filter((reason) => reason !== value));
    }
  };

  const handleFeedbackChange = (event) => {
    setAdditionalFeedback(event.target.value);
  };

  const confirmCancellation = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/subscription/cancel`, {
        reasons: selectedReasons,
        feedback: additionalFeedback,
      });
      closeModal();
      setIsLoading(false);
      setSuccessMessage(t('cancelSuccess'));
      setSubscription(null); // Clear the subscription data
    } catch (error) {
      console.error('There was an error canceling the subscription!', error);
      setError(t('errorFetch'));
      setIsLoading(false);
    }
  };

  return (
    <div className="page-container">
      <div className="header">
        <button className="back-button" onClick={handleBackClick}>
          <FaArrowLeft />
        </button>
        <h1 style={{ color: '#333', fontSize: '1.5em', margin: '0', borderBottom: '1px solid #ddd' }}>
          {t('manageSub')}
        </h1>
      </div>
      {isLoadingData ? (
        <Spinner as="span" className="modal-confirm-button-spinner" animation="border" role="status" aria-hidden="true" />
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : successMessage ? (
        <h2 style={{ color: '#00B2FF', fontSize: '1.5em', textAlign: 'center' }}>{successMessage}</h2>
      ) : subscription ? (
        <>
          <p style={{ fontSize: '1.1em' }}>
            <strong>{t('startDate')}:</strong> {new Date(subscription.startDate).toLocaleDateString()}
          </p>
          <p style={{ fontSize: '1.1em' }}>
            <strong>{t('endDate')}:</strong> {new Date(subscription.expirationDate).toLocaleDateString()}
          </p>

          {/* Show the button only if provider is not Android */}
          {subscription.provider !== 'Android' && (
            <button className="cancel-subscription-button" onClick={openModal}>
              {t('cancelSub')}
            </button>
          )}
        </>
      ) : (
        <p>{t('noSub')}</p>
      )}

      {isModalOpen && (
        <div className="custom-modal-overlay">
          <div className="custom-modal">
            <button style={{ float: 'right', fontSize: '2rem', marginTop: '-15px' }} onClick={closeModal}>
              &times;
            </button>
            <h2 style={{ textAlign: 'center' }}>{t('cancelSub')}</h2>
            <p style={{ textAlign: 'center' }}>
              {t('informationSubCancel')}
            </p>

            <form className="cancellation-reasons">
              {[ 
                { value: 'feature_not_relevant', label: t('featureNotRelevant') },
                { value: 'not_satisfied', label: t('notSatisfied') },
                { value: 'lacks_features', label: t('lacksFeatures') },
                { value: 'too_expensive', label: t('tooExpensive') },
                { value: 'customer_service', label: t('customerService') },
                { value: 'found_alternative', label: t('foundAlternative') },
                { value: 'ease_of_use', label: t('easeOfUse') },
                { value: 'free_plan_enough', label: t('freePlanEnough') },
                { value: 'other', label: `${t('other')}:` },
              ].map(({ value, label }) => (
                <label key={value}>
                  <input type="checkbox" name="reason" value={value} onChange={handleReasonChange} />
                  {label}
                </label>
              ))}
              <textarea
                name="additional_feedback"
                placeholder={t('placeHolderCancelSub')}
                rows="4"
                value={additionalFeedback}
                onChange={handleFeedbackChange}
              ></textarea>
            </form>

            {error && <p className="error-message">{error}</p>}
            {isLoading ? (
              <div className="spinner-center-container">
                <Spinner animation="border" size="lg" style={{ color: '#00B2FF' }} role="status" aria-hidden="true" />
              </div>
            ) : (
              <div className="spinner-center-container">
                <button onClick={confirmCancellation} className="modal-confirm-button">
                  {t('confirmCancellation')}
                </button>
                <button onClick={closeModal} className="modal-close-button">
                  {t('close')}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageSubscription;
