import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import axios from '../../components/AxiosConfig';
import { useUser } from '../userContext/UserContext.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from 'chart.js';
import Checkbox from '@mui/material/Checkbox';
import MainAppBar from '../homePage/AppBar';
import './DashboardApp.css';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import FooterApp from '../../componentLanding/FooterApp.jsx';
import UnlockIcon from '../../icons/UnlockIcon';
import defaultImage from '../../images/book-default-min.png';
import { useTranslation } from 'react-i18next';
import { firstBooks } from '../../constants/global.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend
);

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#00B2FF',
  },
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: '#b2dfdb',
  },
}));

const DashboardApp = ({ authenticated }) => {
  const { userData, loading } = useUser();
  const navigate = useNavigate();
  const [wordsReadData, setWordsReadData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [practicesData, setPracticesData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [recentBook, setRecentBook] = useState();
  const [isDefaultBook, setIsDefaultBook] = useState(true);
  const [hasWordToPractice, setHasWordToPractice] = useState(false);
  const [readingProgress, setReadingProgress] = useState(0);
  const [loadingRecentBook, setLoadingRecentBook] = useState(true);
  const [dailyReminder, setDailyReminder] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (authenticated === false) {
      window.location.href = '/';
    }
  }, [authenticated]);

  useEffect(() => {
    if (userData) {
      setDailyReminder(userData.sendDailyReminder);
    }
  }, [userData]);

  useEffect(() => {
    const fetchWordsStatistics = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/wordStatistics`);
        const data = response.data;
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const wordsRead = daysOfWeek.map(day => data[day] || 0);
        setWordsReadData(wordsRead);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    const fetchPracticesStatistics = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/practiceStatistics`);
        const data = response.data;
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const practices = daysOfWeek.map(day => data[day] || 0);
        setPracticesData(practices);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    const fetchRecentBook = async () => {
      setLoadingRecentBook(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Book/recentbook`);
        const bookData = response.data;
        if(!bookData.title){
          setRecentBook(firstBooks[userData.libraryLanguage]);
        }
        if (bookData?.title) {
          setRecentBook(bookData);
          const hasWordToPracticeRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/hasWordsToPractice/${bookData.id}`);
          setHasWordToPractice(hasWordToPracticeRes.data);
          setIsDefaultBook(false);

          const progress = bookData.currentPosition && bookData.currentPosition > -1 && bookData.totalWordCount
            ? (bookData.currentPosition / bookData.totalWordCount) * 100
            : 0;
          setReadingProgress(progress);
        }
      } catch (error) {
        console.error("Error fetching recent book:", error);
      } finally {
        setLoadingRecentBook(false);
      }
    };
    if(userData){
      fetchWordsStatistics();
      fetchPracticesStatistics();
      fetchRecentBook();
    }

  }, [userData]);

  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setDailyReminder(isChecked);

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/user/reminder`, { sendDailyReminder: isChecked });
    } catch (error) {
      console.error("Failed to update reminder preference:", error);
    }
  };

  const chartData = {
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    datasets: [
      {
        label: t('wordsRead'),
        data: wordsReadData,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
      {
        label: t('wordsPracticed'),
        data: practicesData,
        backgroundColor: 'rgba(153,102,255,0.4)',
        borderColor: 'rgba(153,102,255,1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      {!loading && (
        <>
          <MainAppBar userData={userData} />
          <div className="bg-[#f4e7d3] min-h-screen flex flex-col items-center">
            <div
              className="w-full max-w-3xl p-4"
              style={{ background: '#f3f4f6', borderRadius: '15px', marginTop: '20px' }}
            >
              <section className="my-6">
                <h2
                  className="text-2xl font-semibold text-center"
                  style={{ fontSize: '2.0rem', fontWeight: 'bold', visibility: !loadingRecentBook ? 'visible' : 'collapse' }}
                >
                  {recentBook?.firstBook ? t('beginReading') : t('recentlyRead')}
                </h2>

                {loadingRecentBook ? (
  <div
    className="flex justify-center items-center"
    style={{
      width: '100%',
      height: '200px',
    }}
  >
    <CustomLinearProgress style={{ width: '5rem' }} />
  </div>
) : (
  <div
    className="bg-[#2ac59c] p-4 rounded-md text-black flex flex-col items-center md:mx-auto md:w-1/2"
  >
    {/* Image is made bigger on desktop */}
    <img
  src={recentBook?.imageUrl ?? defaultImage}
  alt={`${recentBook?.title} cover`}
  className="book-cover-image"
/>

    <span style={{ fontSize: '1.2rem', fontWeight: 'bold', marginTop: '10px' }}>
      {recentBook?.title}
    </span>
    <p>{recentBook?.author}</p>
    
    {/* This container is narrower on desktop (md) */}
    <div className="w-full md:w-3/5 flex flex-col items-center">
      <div className="h-2 w-full rounded-full mt-2" style={{ backgroundColor: '#add8e6' }}>
        <div
          className="h-full rounded-full"
          style={{ width: `${readingProgress}%`, backgroundColor: '#00b2ff' }}
        ></div>
      </div>

      <div
  className={`flex mt-3 w-full ${
    (!isDefaultBook && hasWordToPractice)
      ? 'space-x-2'
      : 'justify-center'
  }`}
>
  <button
    className={`
      px-4 py-2 text-white rounded-md font-bold transition-colors
      ${(!isDefaultBook && hasWordToPractice) ? 'w-1/2' : 'w-full max-w-sm'}
    `}
    style={{ background: '#00b2ff', borderRadius: '10px' }}
    onClick={() => navigate(`/reader/${recentBook.id}`)}
  >
    {isDefaultBook ? t('startReading') : t('continueReading')}
  </button>

  {/* Render the second button only if conditions are met */}
  {!isDefaultBook && hasWordToPractice && (
    <button
      className={`
        w-1/2 px-4 py-2 text-white rounded-md font-bold flex items-center justify-center
        ${userData.isSubscriptionActive ? 'hover:bg-[#008fce]' : ''}
      `}
      style={{
        background: userData.isSubscriptionActive
          ? 'rgba(153,102,255,1)'
          : 'rgba(169,169,169,1)',
        borderRadius: '10px',
      }}
      onClick={() =>
        userData.isSubscriptionActive
          ? navigate(`/flashcards/${recentBook.id}`)
          : navigate('/subscription/premium')
      }
    >
      {userData.isSubscriptionActive ? (
        t('startPractice')
      ) : (
        <>
          <UnlockIcon height="24" width="24" margin="3" /> {t('startPractice')}
        </>
      )}
    </button>
  )}
</div>
    </div>
  </div>
)}
              </section>

              <section className="my-6">
                <h2 className="text-2xl font-semibold text-center">{t('dailyLearningChart')}</h2>
                <p className="text-center" style={{ marginTop: '10px', fontSize: '1.1rem' }}>{t('trackYourProgressDaily')}</p>
                <div className="w-full md:w-3/4 lg:w-1/2 mx-auto my-6" style={{ marginTop: '20px' }}>
                  <div className="chart-div">
                    <Bar data={chartData} options={options} />
                  </div>
                </div>
                <label className="flex items-center justify-center my-4">
                  <Checkbox
                    checked={dailyReminder}
                    onChange={handleCheckboxChange}
                    sx={{
                      color: '#00b2ff',
                      '&.Mui-checked': {
                        color: '#00b2ff',
                      },
                    }}
                  />
                  <span>{t('dailyEmailReminder')}</span>
                </label>
              </section>
            </div>
          </div>
          <FooterApp />
        </>
      )}
    </>
  );
};

export default DashboardApp;
