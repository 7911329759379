import React, { useEffect, useState } from "react";
import axios from '../../components/AxiosConfig';
import { useNavigate, useLocation } from "react-router-dom";
import PremiumBox from "./PremiumBox";
import PremiumTable from "./PremiumTable";
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

const PremiumPricingBox = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [premium, setPremium] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingButtons, setLoadingButtons] = useState({});

  // const [digitalGoodsSupported, setDigitalGoodsSupported] = useState(false);
  // const [playBillingService, setPlayBillingService] = useState(null);
  // const [skuDetails, setSkuDetails] = useState(null);

  // useEffect(() => {
  //   const checkDigitalGoodsSupport = async () => {
  //     if ("getDigitalGoodsService" in window) {
  //       try {
  //         const service = await window.getDigitalGoodsService(
  //           "https://play.google.com/billing"
  //         );
  //         const productIds = ['happread_1_month', 'happread_3_months'];
  //         const skuDetails = await service.getDetails(productIds);
  //         setSkuDetails(skuDetails);
  //         setPlayBillingService(service);
  //         setDigitalGoodsSupported(true);
  //       } catch (err) {
  //         console.error("Digital Goods API is not supported:", err);
  //       }
  //     }
  //   };
  //   checkDigitalGoodsSupport();
  // }, []);

  useEffect(() => {
    const fetchPremiumData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/subscription/premium`);
        await premiumDataAdjustment(response.data);
  
        const initialButtonsState = {};
        response.data.plans.forEach(plan => {
          initialButtonsState[plan.month] = false; // Set each plan button to false initially
        });
  
        setLoadingButtons(initialButtonsState); // Update state with the initial button states
        setLoading(false);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
        setError('There was an error fetching the data. Please try again later.');
        setLoading(false);
      }
    };
  
    fetchPremiumData();
  }, [location]);
  

    // Reset loadingButtons when the page is shown again
    useEffect(() => {
      const handlePageShow = () => {
        setLoadingButtons({});
      };
  
      window.addEventListener('pageshow', handlePageShow);
  
      return () => {
        window.removeEventListener('pageshow', handlePageShow);
      };
    }, []);
    
  const premiumDataAdjustment = async (premium) => {
    if ("getDigitalGoodsService" in window){
      try{
        const productIds = ['happread_1_month', 'happread_12_months'];
      const service = await window.getDigitalGoodsService(
                  "https://play.google.com/billing"
                );
      const skuDetails = await service.getDetails(productIds);
      const planPremiumGoogle1Month = skuDetails.find(x => x.itemId === 'happread_1_month');
      const planPremiumGoogle3Month = skuDetails.find(x => x.itemId === 'happread_12_months');
      premium.plans.forEach(plan => {
        if (plan.month === 1) {
          plan.originalYearlyCost = null;
          plan.saveAmount = null;
        
          const monthlyPrice = parseFloat(planPremiumGoogle1Month.price.value).toFixed(2);
          const yearlyCost = (parseFloat(monthlyPrice) * 12).toFixed(2);
        
          plan.yearlyCost = `${yearlyCost} ${planPremiumGoogle1Month.price.currency}`;
          plan.price = `${monthlyPrice} ${planPremiumGoogle1Month.price.currency}`;
        } else if (plan.month === 12) {
          const monthlyPrice = parseFloat(planPremiumGoogle1Month.price.value).toFixed(2);
          const quarterlyPrice = parseFloat(planPremiumGoogle3Month.price.value).toFixed(2);
        
          const yearlyCostFromQuarterly = (parseFloat(quarterlyPrice)).toFixed(2);
          const yearlyCostFromMonthly = (parseFloat(monthlyPrice * 12)).toFixed(2);
          const savings = (parseFloat(yearlyCostFromMonthly) - parseFloat(yearlyCostFromQuarterly)).toFixed(2);
        
          plan.originalYearlyCost = `${yearlyCostFromMonthly} ${planPremiumGoogle1Month.price.currency}`;
          plan.saveAmount = `${savings} ${planPremiumGoogle3Month.price.currency}`;
          plan.yearlyCost = `${yearlyCostFromQuarterly} ${planPremiumGoogle3Month.price.currency}`;
          plan.price = `${parseFloat(quarterlyPrice / 12).toFixed(2)} ${planPremiumGoogle3Month.price.currency}`;
        }
      });
      }catch(error){
        console.log("ex:", error)
      }
    }
    setPremium(premium);
  }
  const handleDigitalGoodsPurchase = async (planId) => {
    setLoadingButtons((prev) => ({ ...prev, [planId]: true }));
    try {

      const productIds = {
        1: 'happread_1_month',
        12: 'happread_12_months'
      };

      const service = await window.getDigitalGoodsService(
        "https://play.google.com/billing"
      );
      const skuDetails = await service.getDetails(Object.values(productIds));

      console.log("skuDetails:", skuDetails);
      if (!skuDetails.length) {
        throw new Error("SKU not found for this plan.");
      }

      const paymentMethods = [{
        supportedMethods: 'https://play.google.com/billing',
        data: {
          sku: productIds[planId]
        }
      }];
      const request = new PaymentRequest(paymentMethods);
      const paymentResponse = await request.show();
      const { purchaseToken } = paymentResponse.details;
      console.log("purchaseToken", purchaseToken);
      console.log("paymentResponse.details", paymentResponse.details);
      const paymentRequest = {
        token: purchaseToken,
        subscriptionId: productIds[planId]
      }
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Subscription/android`, paymentRequest);
        if (response.status === 200) {
          await paymentResponse.complete();
          navigate("/subscription/success");
        } else {
          console.error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        // Handle error here
        console.error("An error occurred:", error);
      }

      // console.log("paymentComplete", paymentComplete);
      // notify googole play you receive payment (not working in the newest api?)
      //await playBillingService.acknowledge(purchaseToken, )



      // const purchase = await playBillingService.purchase(plan.sku);
      // if (purchase.state === "PURCHASED") {
      //   console.log("Purchase successful for product:", plan.title);
      //   navigate("/success");
      // } else {
      //   throw new Error("Purchase was not completed successfully.");
      // }
    } catch (err) {
      console.error("Digital Goods purchase error:", err);
      // setError("There was an error processing the purchase. Please try again.");
    } finally {
      setLoadingButtons(prevState => ({ ...prevState, [planId]: false }));
    }
  };

  const handlePremiumClick = (planId) => {
    setLoadingButtons(prevState => ({ ...prevState, [planId]: true }));
    if ("getDigitalGoodsService" in window) {
    // if (false) {
    try{
      handleDigitalGoodsPurchase(planId);
      
    }catch(error){

    }
    }else{
      axios.post(`${process.env.REACT_APP_API_URL}/api/subscription/pay`, { month: planId })
      .then(response => {
        const url = response.data; // Assuming the response contains the URL in this field
        window.location.href = url;
      })
      .catch(error => {
        console.error('There was an error processing the payment!', error);
        setError('There was an error processing the payment. Please try again later.');
        setLoadingButtons(prevState => ({ ...prevState, [planId]: false }));
      });
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  
  if (loading) {
    return (
      <div className="loading-container text-center my-5">
        <Spinner animation="border" role="status" variant="primary">
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert variant="danger">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className="container" style={{background: '#f0fbff'}}>
      <div className="back-button-container">
        <button className="btn btn-back" onClick={handleBackClick}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 18L9 12L15 6" stroke="#007bff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>
      <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
        <h1 className="display-4 fw-normal">{premium.header.title} 🐼</h1>
        {/* <p className="fs-5 text-muted">{premium.header.text}</p> */}
      </div>
      <div className="row justify-content-center mb-3 text-center">
        {premium.plans.map((plan) => (
          <div key={plan.id} className="col-md-6 col-lg-4 mb-4">
            <PremiumBox
              price={plan.price}
              title={plan.title}
              btnClass={plan.btnClass}
              btnTitle={loadingButtons[plan.month] ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : plan.btnTitle}
              yearlyCost={plan.yearlyCost}
              originalYearlyCost={plan.originalYearlyCost}
              billedYearly={plan.billedYearly}
              saveAmount={plan.saveAmount}
              saveName={plan.saveName}
              yearlyCostName={plan.yearlyCostName}
              perMonth={plan.perMonth}
              feature={plan.featuresTable}
              onClick={() => handlePremiumClick(plan.month)}
              disabled={loadingButtons[plan.month]} // Disable the button if it is loading
            />
          </div>
        ))}
      </div>
      <div>
        <h1 style={{ textAlign: 'center', fontSize: '2.5rem', fontWeight: 'bold'}}>{premium.titleTable}</h1>
        <PremiumTable plans={premium.tableComparations} featuresName={premium.featuresName} />
      </div>
    </div>
  );
};

export default PremiumPricingBox;
