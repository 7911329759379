import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Navbar from '../profile/Navbar';
import axios from '../AxiosConfig';
import { useDrawer } from './DrawerContext';
import { useMediaQuery } from '@mui/material';
import './AppBar.css';
import PandaIcon from '../../icons/PandaIcon';
import PandaMobileIcon from '../../icons/PandaMobileIcon';
import HomePageIcon from '../../icons/HomePageIcon';
import LibraryIcon from '../../icons/LibraryIcon';
import Flag from 'react-world-flags';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TextField, Autocomplete, Popper, Paper } from '@mui/material';
import PremiumIcon from '../../icons/PremiumIcon';
import AccountIcon from '../../icons/AccountIcon';
import UploadIcon from '../../icons/UploadIcon';
import { bookLanguages } from '../../constants/global.js';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';
import C1Icon from '../../icons/C1Icon.js';
import PopularIcon from '../../icons/PopularIcon.js';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import BooksIcon from '../../icons/BooksIcon.js';
import LearningIcon from '../../icons/LearningIcon.js';
import UnlockIcon from '../../icons/UnlockIcon';
// Example SVG Icon Component
function ExampleIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          fill="currentColor"
        />
        <path
          d="M13 7h-2v6h6v-2h-4z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}

const MainAppBar = ({ onUploadText, onDrawerLanguageChange, userData }) => {
  const { t } = useTranslation();
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { leftDrawerOpen, drawerCategoryPosition, handleOpenLeftDrawer, handleCloseLeftDrawer } = useDrawer();
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const autocompleteRef = useRef(null);
  const handleButtonClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };


  const handleLanguageChange = async(event, newValue) => {
    if(newValue === null)
      return;
    
    const lang = newValue.name;
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/user/libraryLanguage`, { libraryLanguage: lang }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        fetchCategoriesData(newValue.name, userData.interfaceLanguage);
        const categoriesFile = require(`../../categories/${newValue.name}.json`);
        const categoriesChange = categoriesFile[userData.interfaceLanguage] || categoriesFile["English"]
        localStorage.setItem('selectedCategory', JSON.stringify(categoriesChange[0]));
        setSelectedCategory({category: categoriesChange[0], language: newValue});
        onDrawerLanguageChange(lang);
        setSelectedLanguage(newValue);
        handleCloseLeftDrawer();
      } else {
        console.error('Failed to save library language');
      }
    } catch (error) {
      console.error('Error saving library language:', error);
    }

    setAnchorEl(null); // Close popper when a language is selected
  };

  const LogoDesktop = () => (
    <div className="logo-container">
      <span className="text-blue">Happ</span>
      <span className="text-black">Read</span>
    </div>
  );

  const handleOpenRightDrawer = () => {
    setRightDrawerOpen(true);
  };

  const handleCloseRightDrawer = () => {
    setRightDrawerOpen(false);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory({category: category, language: selectedLanguage});
    localStorage.setItem('selectedCategory', JSON.stringify(category));
    navigate('/books', { state: { categoryName: category } });
    handleCloseLeftDrawer();
  };

  const fetchCategoriesData = async (libraryLanguage, interfaceLanguage) => {
    try {
      const categoriesFile = require(`../../categories/${libraryLanguage}.json`);
      const categories = categoriesFile[interfaceLanguage] || categoriesFile["English"]
      setCategoriesData(categories);
      const savedCategory = JSON.parse(localStorage.getItem('selectedCategory'));
      if (!isLibraryActive && !savedCategory) {
        localStorage.setItem('selectedCategory', JSON.stringify(categories[0]));
        setSelectedCategory({category: categories[0], language: selectedLanguage});
      }
      if (savedCategory) {
        setSelectedCategory({category: savedCategory, language: selectedLanguage});
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(userData){
        setUserEmail(userData.email);
        fetchCategoriesData(userData.libraryLanguage, userData.interfaceLanguage);
        setSelectedLanguage(bookLanguages.find(language => language.name === userData.libraryLanguage));
    }
  }, [userData]);

  useEffect(() => {
    setAnchorEl(null);
  }, [leftDrawerOpen]);
  
  
  const isLibraryActive = location.pathname === '/library';
  const isHomeActive = location.pathname === '/dashboard';
  const isLearningActive = location.pathname === '/learning' || location.pathname.includes('flashcards');
  const isBooksActive = location.pathname === '/books';

  const getAvatarInitials = (email) => {
    if (!email) return '';
    const [name] = email.split('@');
    const initials = name.split('.').map(part => part.charAt(0)).join('').toUpperCase();
    return initials;
  };

  const handleNavigation = () => {
    window.location.href = '/dashboard';
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'transparent', height: {
          xs: '56px', // height for mobile
          md: '80px', // reduced height for desktop
        },
        borderBottom: '1px solid grey' }}>
        <Container maxWidth="xl" sx={{
        minHeight: {
          xs: '56px', // toolbar height for mobile
          md: '80px', // reduced toolbar height for desktop
        }}}>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component={Link}
              onClick={handleNavigation}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
                color: '#00B2FF'
              }}
            >
              <div className="app-bar-left" style={{ display: 'inline-flex', gap: '.8rem' }}>
                <PandaIcon />
                <LogoDesktop />
              </div>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', color: '#00B2FF' } }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenLeftDrawer}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component={Link}
              onClick={handleNavigation}
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                letterSpacing: '.1rem',
                color: 'inherit',
                textDecoration: 'none',
                color: '#00B2FF'
              }}
            >
              <div className="app-bar-left" style={{ display: 'inline-flex', gap: '.8rem', alignItems: 'center' }}>
                <PandaMobileIcon />
                <LogoDesktop />
              </div>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', margin: '8px' }}>
              <Button
                  key="dashboard"
                  component={Link}
                  to="/dashboard"
                  sx={{
                    my: 2,
                    mr: 2,
                    color: 'black',
                    display: 'block',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    fontFamily: 'Circe, sans-serif',
                    textTransform: 'none',
                    fontFamily: '"Source Code Pro", monospace'
                  }}
                >
                  <span style={{borderBottom: isHomeActive ? '2px solid #00B2FF' : 'inherit'}}>{t('home')}</span>
                </Button>
                <Button
                  key="Home2"
                  component={Link}
                  to="/books"
                  sx={{
                    my: 2,
                    mr: 2,
                    color: 'black',
                    display: 'block',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    fontFamily: 'Circe, sans-serif',
                    textTransform: 'none',
                    fontFamily: '"Source Code Pro", monospace'
                  }}
                >
                  <span style={{borderBottom: isBooksActive ? '2px solid #00B2FF' : 'inherit'}}>{t('books')}</span>
                </Button>
                <Button
                  key="Library"
                  onClick={handleCloseLeftDrawer}
                  component={Link}
                  to="/library"
                  sx={{
                    my: 2,
                    color: 'black',
                    display: 'block',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    fontFamily: 'Circe, sans-serif',
                    textTransform: 'none',
                    marginRight: '15px',
                    fontFamily: '"Source Code Pro", monospace'
                  }}
                >
                  
                  <span style={{borderBottom: isLibraryActive ? '2px solid #00B2FF' : 'inherit'}}>{t('library')}</span>
                </Button>
                <Button
  key="Uploadbook2"
  onClick={handleCloseLeftDrawer}
  component={Link}
                                  to={userData && userData.isSubscriptionActive ? "/learning" : "/subscription/premium"}
  sx={{
    my: 2,
    color: userData?.isSubscriptionActive ? 'black' : 'gray',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    fontFamily: '"Source Code Pro", monospace',
    textTransform: 'none',
    marginRight: '15px',
    cursor: 'pointer',
  }}
>
  {userData?.isSubscriptionActive === false && (
    <span style={{marginRight: '5px'}}>
            <UnlockIcon style={{ height: '20px', width: '20px', marginRight: '10px' }} />
            </span>
  )}
  <span
    style={{
      borderBottom: isLearningActive ? '2px solid #00B2FF' : 'inherit',
    }}
  >
    {t('learning')}
  </span>
</Button>
                {userData && userData.isSubscriptionActive === false && 
                <><PremiumIcon sx={{ marginRight: '10px', color: '#00B2FF' }} />
                <Button
                  key="PremiumDesktop"
                  onClick={handleCloseLeftDrawer}
                  component={Link}
                  to="/subscription"
                  sx={{
                    my: 2,
                    color: 'black',
                    display: 'block',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    fontFamily: 'Circe, sans-serif',
                    textTransform: 'none',
                    marginRight: '15px',
                    fontFamily: '"Source Code Pro", monospace'
                  }}
                >
                  {t('goPremium')}
                </Button>
                </>}
              </div>
            </Box>
            <Box className="app-bar-right" sx={{ flexGrow: 0 }}>
              <Tooltip>
                <IconButton sx={{ p: 0 }} onClick={handleOpenRightDrawer}>
                  <Avatar sx={{
                    bgcolor: '#00B2FF',
                    width: isDesktop ? 40 : 34,
                    height: isDesktop ? 40 : 34
                  }}>
                    {getAvatarInitials(userEmail)}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="left"
        open={leftDrawerOpen}
        onClose={handleCloseLeftDrawer}
        disableEnforceFocus
        sx={{ overflowX: 'hidden' }}
      >
        <Box
          sx={{ width: 250, paddingTop: 4, overflowX: 'hidden' }}
          role="presentation"
        >
          <IconButton onClick={handleCloseLeftDrawer} sx={{ position: 'absolute', right: 0, top: 0, color: '#00B2FF' }}>
            <ArrowBackIosIcon />
          </IconButton>
          {!drawerCategoryPosition && <MenuItem key="dashboard" component={Link} to="/dashboard" onClick={handleCloseLeftDrawer} style={{ width: '100%', backgroundColor: isHomeActive ? '#e0f7fa' : 'inherit' }}>
            <HomePageIcon style={{ marginRight: '10px' }} sx={{ color: '#00B2FF' }} />
            <Typography textAlign="left" sx={{ color: '#000000DE', textDecoration: 'none', 
              fontSize: '1.5rem', marginLeft: '13px', marginTop: '5px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600}}>{t('home')}</Typography>
          </MenuItem>}
          {!drawerCategoryPosition && <MenuItem key="home" component={Link} to="/books" onClick={handleCloseLeftDrawer} style={{ width: '100%', backgroundColor: isBooksActive ? '#e0f7fa' : 'inherit' }}>
            <BooksIcon style={{ marginRight: '10px' }} sx={{ color: '#00B2FF' }} />
            <Typography textAlign="left" sx={{ color: '#000000DE', textDecoration: 'none', 
              fontSize: '1.5rem', marginLeft: '13px', marginTop: '5px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600}}>{t('books')}</Typography>
          </MenuItem>}
          {!drawerCategoryPosition &&<MenuItem
            key="Library"
            component={Link} 
            to="/library" 
            onClick={handleCloseLeftDrawer}
            sx={{ width: '100%', backgroundColor: isLibraryActive ? '#e0f7fa' : 'inherit' }}
          >
            <LibraryIcon sx={{ marginRight: '10px', color: '#00B2FF' }} />
            <Typography
              textAlign="left"
              sx={{ textDecoration: 'none', color: '#000000DE', fontSize: '1.5rem', marginLeft: '10px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}
            >
              {t('library')}
            </Typography>
          </MenuItem>}
          {!drawerCategoryPosition && (
  <MenuItem
    key="UploadBook"
    onClick={handleCloseLeftDrawer}
    sx={{
      width: '100%',
      color: userData?.isSubscriptionActive ? '#000000DE' : 'gray',
      cursor: userData?.isSubscriptionActive ? 'pointer' : 'not-allowed',
    }}
    component={Link}
    to={userData?.isSubscriptionActive ? "/learning" : "/subscription/premium"}
  >
    <LearningIcon
      sx={{
        marginRight: '10px',
        color: userData?.isSubscriptionActive ? '#00B2FF' : 'gold',
      }}
    />
<Box
  sx={{
    display: 'flex',
    alignItems: 'center',
  }}
>
  <Typography
    textAlign="left"
    sx={{
      textDecoration: 'none',
      color: userData?.isSubscriptionActive ? '#000000DE' : 'gray',
      fontSize: '1.5rem',
      marginLeft: '7px',
      fontFamily: '"Source Code Pro", monospace',
      fontWeight: 600,
    }}
  >
    {t('learning')}
  </Typography>
  {userData?.isSubscriptionActive === false && (
    <UnlockIcon
      style={{
        height: '24px', // Increase size
        width: '24px',  // Increase size
        marginLeft: '12px', // Increase left margin
      }}
    />
  )}
</Box>

  </MenuItem>
)}
          {!drawerCategoryPosition &&<MenuItem
            key="Account"
            onClick={() => {
              handleCloseLeftDrawer();
              handleOpenRightDrawer();
            }}
            sx={{ width: '100%'}}
          >
            <AccountIcon sx={{ marginRight: '10px', color: '#00B2FF' }} />
            <Typography
              textAlign="left"
              component={Link}
              sx={{ textDecoration: 'none', color: '#000000DE', fontSize: '1.5rem', marginLeft: '14px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}
            >
              {t('account')}
            </Typography>
          </MenuItem>}
          {!drawerCategoryPosition && userData && userData.isSubscriptionActive === false &&<MenuItem
            key="Premium"
            component={Link}
            to="/subscription" 
            onClick={handleCloseLeftDrawer}
            sx={{ width: '100%' }}
          >
            <PremiumIcon sx={{ marginRight: '10px', color: '#00B2FF' }} />
            <Typography
              textAlign="left"
              sx={{ textDecoration: 'none', color: '#000000DE', fontSize: '1.5rem', marginLeft: '10px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}
            >
              {t('goPremium')}
            </Typography>
          </MenuItem>}
          {!drawerCategoryPosition && <Divider sx={{ borderColor: '#DDD', borderWidth: 1.5 }}/>}
          {/* <MenuItem
            key="Account"
            onClick={handleCloseLeftDrawer}
            sx={{ width: '100%', backgroundColor: isLibraryActive ? '#e0f7fa' : 'inherit' }}
          > */}
            {/* <AccountIcon sx={{ marginRight: '10px', color: '#00B2FF' }} /> */}
            {isBooksActive && <Typography
              textAlign="left"
              component={Link}
              //to="/premium"
              sx={{ textDecoration: 'none', color: '#00B2FF', fontSize: '1.3rem', marginLeft: '14px', fontFamily: 'Roboto, sans-serif', fontFamily: '"Source Code Pro", monospace', fontWeight: 700 }}
            >
              {t('contentOfBooks')}
            </Typography>}
          {/* </MenuItem> */}
          {isBooksActive && <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <Button
              aria-haspopup="true"
              onClick={handleButtonClick}
              sx={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '1.0rem',
                textTransform: 'none',
                marginRight: '15px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '@media (min-width: 600px)': {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: 'auto',
                },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '16px', marginLeft: '5px', fontFamily: '"Source Code Pro", monospace', fontWeight: 800, border: '1px solid grey', borderRadius: '7px', padding: '5px', marginBottom: '10px' }}>
              <span style={{
                    display: 'inline-block',
                    maxWidth: '170px',
                    minWidth: '150px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    verticalAlign: 'middle',
                    color: 'grey',
                    fontSize: '1rem'
                  }}>{t('language')}</span>
                <Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
                  <Flag code={selectedLanguage?.flag} style={{ width: '20px', marginLeft: '8px' }} />
                  <ArrowDropDownIcon />
                </Box>
              </Box>
            </Button>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1300, width: 175}}>
              <Paper ref={autocompleteRef} sx={{ width: 175, bgcolor: 'white', marginLeft: '25px' }} onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
                <Autocomplete
                  options={bookLanguages}
                  getOptionLabel={(option) => option.name}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  inputValue={searchTerm}
                  onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      placeholder="Search language"
                      InputProps={{
                        ...params.InputProps,
                        style: { fontSize: '16px' } // Adjust the font size here
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          fontFamily: '"Source Code Pro", monospace',
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} sx={{fontFamily: '"Source Code Pro", monospace'}}>
                      <Flag code={option.flag} style={{ width: '20px', marginRight: '8px' }} />
                      {option.name}
                    </Box>
                  )}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  open={Boolean(anchorEl)}
                  sx={{ width: 175 }}
                />
              </Paper>
            </Popper>
          </Box>}
          {isBooksActive && <Typography
              textAlign="left"
              component={Link}
              //to="/premium"
              sx={{ textDecoration: 'none', color: '#00B2FF', fontSize: '1.2rem', marginLeft: '14px', marginTop: '10px',marginBottom: '10px', fontFamily: '"Source Code Pro", monospace', fontWeight: 700 }}
            >
              {t('popularCategories')}
            </Typography>}
            {isBooksActive &&<MenuItem
            key="popular"
            onClick={() => handleCategoryClick(categoriesData[0])}
            style={{ width: '100%', backgroundColor: selectedCategory?.category?.Name === categoriesData[0]?.Name ? '#e0f7fa' : 'inherit', marginTop: '10px' }}
          >
            <div style={{marginLeft: '-7px'}}>
            <PopularIcon/>
            </div>
            <Typography textAlign="left" sx={{ marginLeft: '5px', fontSize: '18px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}>
              {categoriesData[0]?.Name}
            </Typography>
          </MenuItem>}
          {isBooksActive &&<MenuItem
                key="A1"
                 onClick={() => handleCategoryClick(categoriesData[1])}
                 style={{ width: '100%', backgroundColor: selectedCategory?.category?.Name === categoriesData[1]?.Name ? '#e0f7fa' : 'inherit' }}
              >
                <FiberManualRecordIcon style={{ fontSize: '10px', marginRight: '10px' }}/>
                <Typography textAlign="left" sx={{ fontSize: '18px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}>
                  <span>{categoriesData[1]?.Name}</span>&nbsp;
                  <span style={{color: '#00B2FF'}}>[A1]</span>
                  </Typography>
          </MenuItem>}
          {isBooksActive &&<MenuItem
                key="B1"
                onClick={() => handleCategoryClick(categoriesData[2])}
                style={{ width: '100%', backgroundColor: selectedCategory?.category?.Name === categoriesData[2]?.Name ? '#e0f7fa' : 'inherit' }}
              >
                <FiberManualRecordIcon style={{ fontSize: '10px', marginRight: '10px' }}/>
                <Typography textAlign="left" sx={{ fontSize: '18px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600 }}>
                <span>{categoriesData[2]?.Name}</span>&nbsp;
                  <span style={{color: '#00B2FF'}}>[B1]</span>
                </Typography>
          </MenuItem>}
          {isBooksActive &&<MenuItem
            key="C1"
            onClick={() => handleCategoryClick(categoriesData[3])}
            style={{ width: '100%', backgroundColor: selectedCategory?.category?.Name === categoriesData[3]?.Name ? '#e0f7fa' : 'inherit' }}
          >
            <FiberManualRecordIcon style={{ fontSize: '10px', marginRight: '10px' }}/>
            <Typography textAlign="left" sx={{ fontSize: '18px', fontFamily: '"Source Code Pro", monospace', fontWeight: 600, marginBottom: '15px' }}>
            <span>{categoriesData[3]?.Name}</span>&nbsp;
              <span style={{color: '#00B2FF'}}>[C1]</span>
            </Typography>
          </MenuItem>}
          {isBooksActive && <Typography
              textAlign="left"
              component={Link}
              //to="/premium"
              sx={{ textDecoration: 'none', color: '#00B2FF', fontSize: '1.2rem', marginLeft: '14px', fontFamily: '"Source Code Pro", monospace', fontWeight: 700 }}
            >
              {t('allCategories')}
            </Typography>}
          {isBooksActive && (
            categoriesData.slice(4).map((category, index) => (
              <MenuItem
                key={index}
                onClick={() => handleCategoryClick(category)}
                style={{ width: '100%', backgroundColor: selectedCategory?.category?.Name === category?.Name ? '#e0f7fa' : 'inherit',}}
              >
                <span style={{ display: 'inline-block', width: '5px', height: '5px', backgroundColor: '#00B2FF', marginRight: '10px' }}></span>
                <Typography textAlign="left" sx={{ fontSize: '17px', fontFamily: '"Source Code Pro", monospace', fontWeight: 500 }}>{category.Name}</Typography>
              </MenuItem>
            ))
          )}
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={rightDrawerOpen}
        onClose={handleCloseRightDrawer}
      >
        <Box
          sx={{ width: 300 }}
          role="presentation"
        >
          <IconButton onClick={handleCloseRightDrawer} sx={{ position: 'absolute', left: 0, top: 0, color: '#00B2FF', zIndex: 1000 }}>
            <ArrowForwardIosIcon />
          </IconButton>
          <Navbar />
        </Box>
      </Drawer>
    </>
  );
};

export default MainAppBar;
