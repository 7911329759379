import React, { useState, useEffect, useRef } from 'react';
import '../homePage/BooksGrid.css';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import axios from '../../components/AxiosConfig';
import defaultImage from '../../images/book-default-min.png';
import { Link } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Checkbox from '@mui/material/Checkbox';
import FooterApp from '../../componentLanding/FooterApp';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LearningBooksGrid = () => {
  const [books, setBooks] = useState(null);
  const [page, setPage] = useState({ skip: 0, limit: 12 });
  const [loading, setLoading] = useState(true);
  const [allElements, setAllElements] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);
  const [wordsReadData, setWordsReadData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const scrollContainerRef = useRef(null);
  const { t } = useTranslation();

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const chartData = {
    labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
    datasets: [

      {
        label: t('wordsPracticed'),
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(153,102,255,0.4)',
        borderColor: 'rgba(153,102,255,1)',
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/wordStatistics`);
        const data = response.data;
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const wordsRead = daysOfWeek.map(day => data[day] || 0);
        setWordsReadData(wordsRead);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchStatistics();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/${page.skip}/${page.limit}`);
        const data = response.data;
        setAllElements(data.count);

        setBooks(prevBooks => (prevBooks ? [...prevBooks, ...data.bookPractice] : data.bookPractice));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (container && container.scrollTop + container.clientHeight >= container.scrollHeight - 100 && !loading && page.skip + page.limit < allElements) {
      loadMoreBooks();
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [loading, page, allElements]);

  const loadMoreBooks = () => {
    setPage(prevPage => ({
      ...prevPage,
      skip: prevPage.skip + prevPage.limit
    }));
  };

  const handleImageLoaded = (bookId) => {
    setLoadedImages(prev => ({ ...prev, [bookId]: true }));
  };

  const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#9966FF',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#b2dfdb',
    },
  }));

  return (
    <>
      <div
        ref={scrollContainerRef}
        style={{
          background: '#F8FAFB',
          borderRadius: '30px',
          marginTop: '10px',
          marginBottom: '30px',
          minHeight: '300px',
          maxHeight: '800px',
          overflowY: 'auto',
          scrollbarWidth: 'thin', // for Firefox
        }}
      >
        <style>
          {`
            /* For Webkit browsers */
            ::-webkit-scrollbar {
              width: 8px; /* Adjust the width */
            }
            ::-webkit-scrollbar-track {
              background: #f1f1f1;
              border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb {
              background: #ccc;
              border-radius: 10px;
            }
            ::-webkit-scrollbar-thumb:hover {
              background: #aaa;
            }
          `}
        </style>

{/* Display the button and message if there are no books */}
{books && books.length === 0 && !loading && (
  <div style={{ textAlign: 'center', padding: '6rem' }}>
    <Link to="/books">
      <button
        style={{
          backgroundColor: '#00b2ff',
          color: 'white',
          padding: '15px 30px', // Increase padding for a larger button
          borderRadius: '5px',
          fontSize: '1.25rem', // Increase font size
          fontWeight: 'bold',
          cursor: 'pointer',
          border: 'none',
          transition: 'background-color 0.3s ease',
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = '#008fce')}
        onMouseOut={(e) => (e.target.style.backgroundColor = '#00b2ff')}
      >
        Start reading
      </button>
    </Link>
  </div>
)}


        <div className="books-list">
          {books &&
            books.map((book) => (
              <div
                key={book.book.id}
                className="book-row"
                style={{
                  display: loadedImages[book.book.id] ? 'flex' : 'none',
                  alignItems: 'center',
                  padding: '10px',
                  borderBottom: '1px solid #ddd',
                }}
              >
                <img src={book.book.imageUrl || defaultImage} alt={book.book.title} onLoad={() => handleImageLoaded(book.book.id)} />
                <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                  <Link to={`/bookdetails/${book.book.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <span
                      className="book-name"
                      style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                      }}
                    >
                      {book.book.title}
                    </span>
                  </Link>
                  <span className="book-author" style={{ fontSize: '1rem', color: '#555', marginTop: '3px' }}>
                    {book.book.author}
                  </span>
                  { (
                    <Box className="progress-container">
                      <CustomLinearProgress
                        variant="determinate"
                        value={book.totalCount === 0 ? 0 : (book.learnedCount / book.totalCount) * 100}
                      />
                    </Box>
                  )}
                  <div className="mobile-action">
                    <span>
                      {book.learnedCount} / <span style={{ color: '#9966FF', fontWeight: 'bold' }}>{book.totalCount}</span> words to practice
                    </span>
                    <Link to={book.totalCount === book.learnedCount ? `/reader/${book.book.id}`: `/flashcards/${book.book.id}`}>
                      <button
                        className="mt-3 px-4 py-2 bg-[#00b2ff] text-white rounded-md hover:bg-[#008fce] transition-colors"
                        style={{ background: '#00b2ff', borderRadius: '10px', fontWeight: 'bold', fontSize: '1.1rem' }}
                      >
                        {book.totalCount === book.learnedCount ? t('readToPractice') : t('startPractice')}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {(loading || Object.values(loadedImages).some((value) => value === false)) && (
          <Box className="books-spinner">
            <div style={{ marginTop: '10px' }}>
              <BarLoader size={25} color="#00B2FF" />
            </div>
          </Box>
        )}
      </div>

      {!loading && (
        <section className="my-6" style={{ background: '#F8FAFB', borderRadius: '30px' }}>
          <h2 className="text-2xl font-semibold text-center">{t('dailyLearningChart')}</h2>
          <p className="text-center" style={{ marginTop: '10px', fontSize: '1.1rem' }}>
            {t('trackYourProgressDaily')}
          </p>
          <div className="w-full md:w-3/4 lg:w-1/2 mx-auto my-6" style={{ marginTop: '20px' }}>
            <div className="chart-div">
              <Bar data={chartData} options={options} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default LearningBooksGrid;
