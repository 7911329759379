import React, { useState, useEffect } from 'react';
import './Flashcard.css';
import axios from '../../components/AxiosConfig';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useParams } from 'react-router-dom';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { voiceLanguage } from '../../constants/global.js';

const Flashcard = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRevealed, setIsRevealed] = useState(false);
  const [wordsLength, setWordsLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [continueLearning, setContinueLearning] = useState(true);
  const [nextData, setNextData] = useState();
  const [wordsLeftLearn, setWordsLeftLearn] = useState(0);
  const [currentWordData, setCurrentWordData] = useState({context: [], word: ''});
  const { bookId } = useParams();
  const [wordsData, setWordsData] = useState([]);
  const { t } = useTranslation();
  const [voiceEnabled, setVoiceEnabled] = useState();
  const [availableVoices, setAvailableVoices] = useState(window.speechSynthesis.getVoices());

  window.speechSynthesis.onvoiceschanged = () => {
    setAvailableVoices(window.speechSynthesis.getVoices());
  };

  const continuePracticing = () => {
    setWordsLength(nextData.length);
    setWordsLeftLearn(nextData.length);
    setWordsData(nextData);
    setCurrentIndex(0);
    setCurrentWordData(nextData[0]);
  };

  const handleSpeakClick = () => {
    const word = currentWordData.word.toLowerCase();

    const utterance = new SpeechSynthesisUtterance(word);
    if (voiceEnabled.lang) {
      const selectedVoice = availableVoices.find(voice => voice.name === voiceEnabled.lang);
      if (selectedVoice) {
        utterance.voice = selectedVoice;
      }
    }
    window.speechSynthesis.speak(utterance);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/words/${bookId}`);
        const data = response.data;
        setWordsLength(data.words.length);
        setWordsLeftLearn(data.words.length);
        setWordsData(data.words);
        setLoading(false);
        setCurrentWordData(data.words[currentIndex]);
        const isVoiceEnabled = voiceLanguage.find(x => x.name === data.bookLanguage);
        setVoiceEnabled(isVoiceEnabled);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [bookId]);

  const handleReveal = () => {
    setIsRevealed(true);
  };

  const handleNextWord = async (type) => {
    if (type === "YES") {
      setWordsLeftLearn((prevIndex) => prevIndex - 1);
  
      // Remove the current word from the wordsData array
      const updatedWordsData = wordsData.filter(word => word.id !== currentWordData.id);
      setWordsData(updatedWordsData);
      if (updatedWordsData.length === 0) {
        setLoading(true);

        await axios.put(`${process.env.REACT_APP_API_URL}/api/practice/word/${bookId}/${currentWordData.id}`);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/practice/words/${bookId}`);
        const newWords = response.data;
        setContinueLearning(response.data.words.length > 0);
        setNextData(newWords.words);
        setLoading(false);

      } else {
        // Set the next word or loop back to the start
        const newIndex = currentIndex % updatedWordsData.length;
        setCurrentIndex(newIndex);
        setCurrentWordData(updatedWordsData[newIndex]);
        axios.put(`${process.env.REACT_APP_API_URL}/api/practice/word/${bookId}/${currentWordData.id}`);

      }
    } else {
      // Advance to the next word or loop back to start if at the end
      setCurrentIndex((prevIndex) => (prevIndex + 1) % wordsData.length);
      setCurrentWordData(wordsData[(currentIndex + 1) % wordsData.length]);
    }
  
    setIsRevealed(false);
  };
  
  
  

  if(loading){
    return (
      <div>
        <CircularProgress/>
      </div>
    );
  }
  if (wordsData.length === 0 && !loading) {
    return (
      <div className="flashcard-container" style={{ padding: '20px', maxWidth: '700px', margin: '0 auto', textAlign: 'center' }}>
        <h2 style={{ fontSize: '1.8rem', fontWeight: 'bold', marginBottom: '10px' }}>
          {t('finishPracticing')}
        </h2>
        <p style={{ fontSize: '1.2rem', color: '#666', marginBottom: '20px' }}>
          {t('endPracticing')}
        </p>
        <Stack direction="row" spacing={2} justifyContent="center" style={{ marginTop: '20px' }}>
          {continueLearning && <Button
            variant="contained"
            color="primary"
            onClick={() => continuePracticing()}
            style={{
              padding: '10px 20px',
              fontSize: '1rem',
              fontWeight: 'bold',
              borderRadius: '8px',
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
              transition: 'transform 0.2s, box-shadow 0.2s',
            }}
            onMouseOver={(e) => {
              e.target.style.transform = 'scale(1.05)';
              e.target.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
            }}
            onMouseOut={(e) => {
              e.target.style.transform = 'scale(1)';
              e.target.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.15)';
            }}
          >
            {t('continuePracticing')}
          </Button>}
          <Link to="/learning" style={{ textDecoration: 'none' }}>
            <Button
              variant="outlined"
              color="secondary"
              style={{
                padding: '10px 20px',
                fontSize: '1rem',
                fontWeight: 'bold',
                borderRadius: '8px',
                borderColor: '#ff4081',
                color: '#ff4081',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
                transition: 'transform 0.2s, box-shadow 0.2s',
              }}
              onMouseOver={(e) => {
                e.target.style.transform = 'scale(1.05)';
                e.target.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
              }}
              onMouseOut={(e) => {
                e.target.style.transform = 'scale(1)';
                e.target.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.15)';
              }}
            >
              {t('finish')}
            </Button>
          </Link>
        </Stack>
        <p style={{ fontSize: '1rem', color: '#999', marginTop: '20px' }}>
          {t('keepUp')}
        </p>
      </div>
    );
    
    
  }

  return (
    <div className="flashcard-container">
      <div className="top-bar">
        <p className="word-count" style={{fontSize: '1.1rem', fontWeight: 'bold'}}>{t('word')} {wordsLeftLearn} {t('of')} {wordsLength}</p>
        <Link to={`/learning`}>
          <IconButton aria-label="close" className="close-button">
            <CloseIcon />
          </IconButton>
        </Link>
      </div>

      <div className="sentence-container">
  <p className="sentence" style={{fontSize: '1.6rem'}}>
    ...
    {currentWordData.context.split(' ').map((word, index) => (
      <React.Fragment key={index}>
        <span className={word === currentWordData.word ? 'highlight' : ''}>
          {word}
        </span>
        {index < currentWordData.context.split(' ').length - 1 && ' '}
      </React.Fragment>
    ))}
    ...
  </p>
</div>


      <div className="flashcard-blocks">
        <div className="flashcard-block" onClick={handleSpeakClick}>
          <p style={{ fontSize: '2rem' }}>{currentWordData.word}</p>
          <button className="audio-btn" style={{visibility: voiceEnabled ? 'visible': 'collapse'}}><VolumeUpIcon/></button>
        </div>
        <div className="flashcard-block" onClick={handleReveal} style={{ fontSize: '2rem' }}>
          {isRevealed ? <p>{currentWordData.revealWord}</p> : <p>?</p>}
        </div>
      </div>

      {isRevealed && (
        <div className="feedback-container">
          <p style={{ fontWeight: 'bold', fontSize: '1.3rem' }}>{t('didYouRemember')}</p>
          <Stack 
  direction="row" 
  spacing={2} 
  justifyContent="center" 
  alignItems="center"
  sx={{ marginTop: 2 }}
>
  <Button
    onClick={() => handleNextWord("NO")}
    style={{
      background: 'linear-gradient(135deg, #ff6b6b, #ff8c8c)',
      color: '#fff',
      borderRadius: '20px',
      padding: '10px 20px',
      fontSize: '16px',
      fontWeight: '500',
      transition: 'background 0.3s, transform 0.2s',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
      fontFamily: '"Source Code Pro", monospace',
      '&:hover': {
        background: 'linear-gradient(135deg, #ff4c4c, #ff6b6b)',
        transform: 'scale(1.03)',
      },
    }}
  >
    {t('Nope')}
  </Button>
  
  <Button
    onClick={() => handleNextWord("NO")}
    style={{
      background: 'linear-gradient(135deg, #f39c12, #f1c40f)',
      color: '#fff',
      borderRadius: '20px',
      padding: '10px 20px',
      fontSize: '16px',
      fontWeight: '500',
      transition: 'background 0.3s, transform 0.2s',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
      fontFamily: '"Source Code Pro", monospace',
      '&:hover': {
        background: 'linear-gradient(135deg, #e67e22, #f39c12)',
        transform: 'scale(1.03)',
      },
    }}
  >
    {t('almost')}
  </Button>
  
  <Button
    onClick={() => handleNextWord("YES")}
    style={{
      background: 'linear-gradient(135deg, #2ecc71, #27ae60)',
      color: '#fff',
      borderRadius: '20px',
      padding: '10px 20px',
      fontSize: '16px',
      fontWeight: '500',
      transition: 'background 0.3s, transform 0.2s',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
      fontFamily: '"Source Code Pro", monospace',
      '&:hover': {
        background: 'linear-gradient(135deg, #27ae60, #1abc9c)',
        transform: 'scale(1.03)',
      },
    }}
  >
    {t('yes')}
  </Button>
</Stack>

        </div>
      )}

      <p className="instructions" style={{ fontSize: '1rem', marginTop: '20px' }}>
        {t('revealTheAnswer')}
      </p>
    </div>
  );
};

export default Flashcard;
