import React, { useState, useEffect } from 'react';
import "../percentageSettings/PercentageSettings.css";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@mui/material';

const PercentagePopup = ({ showPercentagePopup, onClosePercentagePopup, theme, onSpecificLocation, pageIndexes, total }) => {

  const [isVisible, setIsVisible] = useState('hidden');
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (showPercentagePopup) {
      setIsVisible('visible');
    } else {
      setIsVisible('hidden');
    }
  }, [showPercentagePopup]);

  const onClose = () => {
    setIsVisible('hidden');
    onClosePercentagePopup();
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
  
    if (newValue === '' || /^\d+$/.test(newValue)) {
      setValue(newValue);
  
      if (newValue < 0 || newValue > total) {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setError(true);
    }
  };
  

  const goToLocation = () => {
    if (error) {
      return;
    }
    onSpecificLocation(value);
    setValue('');
  };

  return (
    <>
      {isVisible === 'visible' && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(1.5px)',
          zIndex: 999
        }} onClick={onClose} />
      )}
      <div className='percentageLimitContent' style={{
        visibility: isVisible,
        position: 'fixed',
        top: '5rem',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: '300px',
        boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
        backgroundColor: 'white',
        zIndex: 1000,
        background: theme === 'white' ? 'white' : (theme === 'light' ? '#DBD6C5' : '#565656')
      }}>
        <span className="closeLimitPopup" onClick={() => onClose(false)}>
          <CloseIcon style={{ width: '20px', height: '20px' }} />
        </span>
        <div className='percentageLimitContent__header' style={{ color: theme === 'dark' ? 'white' : 'black', marginBottom: '10px' }}>
          {t('goToSpecificLocation')}
        </div>
        <div className='percentageLimitContent__description' style={{ color: theme === 'dark' ? 'white' : 'black', display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
          <span style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            {t('youAreCurrentlyAtLocation')}&nbsp;<span style={{ fontWeight: 'bold' }}>{pageIndexes.firstPageIndex}</span>
          </span>
          <span style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            {t('enterLocationFrom')}&nbsp;<span style={{ fontWeight: 'bold' }}>0</span>&nbsp;{t('to')}&nbsp;<span style={{ fontWeight: 'bold' }}>{total}</span>
          </span>
          <TextField
            sx={{ width: '100%', marginTop: 0.1 }}
            id="outlined-basic"
            size="small"
            type="number"
            value={value}
            onChange={handleChange}
            error={error}
          />
          <Button variant="outlined" style={{ marginTop: '10px', fontWeight: 'bold', border: '1px solid #00B2FF', color: '#00B2FF' }} onClick={goToLocation}>
            {t('goToLocation')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PercentagePopup;
