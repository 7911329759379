import React, { useState, useEffect } from 'react';
import './HomePage.css'
import {  useParams } from 'react-router-dom';
import MainAppBar from './AppBar';
import SearchBar from '../searchBar/SearchBar';
import SearchIcon from '@mui/icons-material/Search';
import './SearchPage.css'
import CircularProgress from '@mui/material/CircularProgress';
import axios from '../../components/AxiosConfig';
import Box from '@mui/material/Box';
import FoundBooks from '../../images/found_books.webp';

const SearchPage = () => {
    const { value } = useParams();
    const [books, setBooks] = useState(null);
    const [loading, setLoading] = useState();

    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      handleRequestSearch(value);
  }, [value]);

    const fetchData = async (request) => {
      if(request === undefined){
        return;
      }
      setBooks(null);
      try {
        setLoading(true);
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Book/basicbook/0/10000`);
        const data = response.data;
        //setAllElements(data.totalCount);
        setBooks((prevItems) => {
          if (prevItems === null) {
            return [...data.books.filter(x => x.title.toLowerCase().includes(request.toLowerCase()))]
          } else {
            return [...prevItems, ...data.books.filter(x => x.title.includes(request))];
          }
        });
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch books:', error); // Error handling
        setLoading(false); // Ensure loading is set to false on error
      }
    };
    
    const handleChange = (newValue) => {
        setInputValue(newValue);
    };

    const handleRequestSearch = (request) => {
      fetchData(request);
    };
    return (
        <div>
          <MainAppBar />
          <div style={{marginLeft: '10px', marginRight: '10px'}}>
          <SearchBar
      style={{
        margin: '10px auto',
        maxWidth: 500,
        borderRadius: '25px',
        //backgroundColor: '#f0f0f0',
        color: '#00B2FF',
        marginBottom: '20px',
        marginTop: '20px'
      }}
      value={inputValue} 
      onChange={handleChange}
      onRequestSearch={handleRequestSearch}
      closeIcon={<SearchIcon />}
    />
    </div>
    <div className="search-container">
          <img src={FoundBooks} style={{
            height: '64px',
            width: '64px',
            borderRadius: '25px',
            marginRight: '10px'
          }}/>
        <span>Found books</span>
      </div>
        <div className="books-search-grid" style={{margin: 'auto', marginTop: '25px', background: '#F8FAFB', borderRadius: '30px'}}>
        
        {books && books.map((book) => (
         <a href={`/reader/${book.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>   
          <div key={book.id} className="book-search-tile">
          <img src={book.imageUrl} alt={book.title}/>

            <span className="book-search-name">{book.title}</span>
            <span className="book-search-author">{book.author}</span>
          </div>
          </a>
        ))}
      </div>
      {books === null && loading === false && <h2 style={{textAlign: 'center'}}>Brak wyników wyszukiwania</h2>}
      {loading && <Box className="books-spinner">
            <CircularProgress size={25}/>
          </Box>}
      </div>
    );
  };
  
  export default SearchPage;